<template>
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">{{t('10030')}}</i>
			</a>
			<a @click="openShare" class="nav__btn nav__btn--map">
				<!--<i class="icon icon-share--white"></i>-->
			</a>
		</div>
	</nav>
	<nav class="nav" style="position:fixed;top:0;left:0;width:100%;z-index:5;background-color:#fff;" v-show="topNavShow">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text" v-show="hotel.length > 0" v-if="lang==='ko'">
					{{hotel[0].HOTEL_NM}}
				</span>
				<span class="nav__text" v-show="hotel.length > 0" v-else>
					{{hotel[0].HOTEL_ENM}}
				</span>
			</p>
			<a @click="openShare" class="nav__btn nav__btn--map">
				<i class="icon icon-share"></i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container" :style="{visibility: hotel.length > 0 && hotelImages.length > 0 ? 'visible' : 'hidden'}">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub detail">
			<!-- gallery -->
			<section class="gallery">
				<div class="gallery__slider">
					<swiper v-if="hotelImages.length > 0" @slideChange="setSlideCurrentIndex" data-currentnum=".gallery__currentnum--1" >
						<swiper-slide v-for="image in hotelImages" :key="image.IMG_URL">
							<div class="gallery__imgbox noneimage">
								<div class="imagebg" :style="{'background-image': 'url(&quot;' + image.IMG_URL + '&quot;)'}" :alt="image.IMG_URL"/>
							</div>
							<!-- <img src="https://dummyimage.com/750X664/f0f/0f0" :alt="image.HOTEL_NO"> -->
						</swiper-slide>
					</swiper>
				</div>
					<div class="gallery__meta">
						<router-link :to="'/layers/gallery/' + hotelNo" class="btn-gallerymore">{{t('10521')}}</router-link>
						<span class="gallery__badge">
							<span v-if="nowSlideIndex === 0">{{t('10522')}}</span>
							<span v-else-if="hotelImages[nowSlideIndex].IMG_DESC !== null && hotelImages[nowSlideIndex].IMG_DESC.length > 0">{{hotelImages[nowSlideIndex].IMG_DESC}}</span>
							<strong class="gallery__currentnum gallery__currentnum--1">{{nowSlideIndex + 1}}</strong>
							<i class="gallery__dot"></i>
							<small>{{hotelImages.length}}</small>
						</span>
				</div>
			</section>
			<!-- contact -->
			<section class="content">
				<div class="box">
					<div class="detail__wrap">
						<!-- 숙소 기본정보 -->
						<header class="detail__header" v-if="hotel.length > 0">
							<div class="detail__title">
								<h2 class="title ellipsis" v-if="lang==='ko'">{{ hotel[0].HOTEL_NM }}</h2>
								<h2 class="title ellipsis" v-else>{{ hotel[0].HOTEL_ENM }}</h2>
								<div class="detail__slogan"  v-show="hotelInfo.length > 0" v-if="lang==='ko'">
									{{hotelInfo[0].address}}
								</div>
								<div class="detail__slogan" v-show="hotelInfo.length > 0" v-else>
									{{hotelInfo[0].address_E}}
								</div>
							</div>
							<div class="detail__contact">
								<div class="detail__grad info-grad">
									<template v-if="hotel[0].REVIEWS > 0">
										<span class="list__icon info-icon">
											<i class="icon icon-star"></i>
										</span>
										<span class="list__gradnum info-gradnum">
											{{hotel[0].SCORE.toFixed(1)}}
										</span>
										<span class="list__gradcounter info-gradcounter">
											({{hotel[0].REVIEWS.toLocaleString("en-US")}})
										</span>
									</template>
								</div>
								<div class="detail__sns" v-if="hotelInfo.length > 0">
									<a v-if="hotelInfo[0].kakako_id.length > 0" :href="'https://pf.kakao.com/' + hotelInfo[0].kakako_id">
										<i class="icon icon-kakao">{{t('10523')}}</i>
										{{t('10524')}}
									</a>
									<a v-if="hotelInfo[0].tel_no.length > 0" :href="'tel:' + hotelInfo[0].tel_no">
										<i class="icon icon-tel"></i>
										{{t('10269')}}
									</a>
								</div>
							</div>
						</header>
						<!-- 숙소 상세정보 -->
						<div class="detail__body">
							<!-- 숙소 추가 정보 -->
							<article class="detail__meta" v-if="hotel.length > 0 && hotelInfo.length > 0">
								<a @click="handleOpenCoupon" class="btn-getcoupon" v-if="hotelInfo[0].cp_max_amt">
									<span>
										즉시 할인 쿠폰&nbsp;&nbsp;
										<strong>최대 {{hotelInfo[0].cp_max_amt.toLocaleString("en-US")}}원 쿠폰 확인</strong>
										<i class="icon icon-ticket"></i>
									</span><!-- 재번역 체크 -->
								</a>
								<div class="getevent" v-if="hotel[0].PROMO_INFO">
									<div class="getevent__coupon">{{t('10527')}}</div>
									<div class="getevent__wrap">
										<div class="getevent__title">
											{{t('10062')}}
										</div>
										<p class="getevent__description ellipsis">
											{{hotel[0].PROMO_INFO}}
											<!-- {{hotel[0].PROMO_INFO}} -->
										</p>
									</div>
									<div class="getevent__dot"></div>
								</div>
								<ul class="search__list">
									<li>
										<router-link to="/layers/date">
											<div class="search__inner">
												<i class="icon icon-date">{{t('10586')}}</i>
												<input type="text" class="search__ipt active" readonly
													:value="dateRangeText">
											<span>{{t('10528')}}</span>
											</div>
										</router-link>
									</li>
								</ul>
							</article>
							<!-- 숙소 탭 --->
							<!-- [D] 클릭시 해당 영역으로 스크롤 이동, 버튼에 active 클래스 추가 -->
							<div>
								<article class="tab tab--small" ref="navRef" style="background-color:#fff;-webkit-transform: translateZ(0);">
									<a 
										@click="onClicksection('detailchoice',0)" 
										:class="{'active': sectionClick === -1 ? sectionCount <= 1 : sectionClick === 0}"
									>
										{{t('10529')}}
									</a>
									<a 
										@click="onClicksection('detailinfo',1)" 
										:class="{'active': sectionClick === -1 ? sectionCount === 2 : sectionClick === 1}"
									>
										{{t('10530')}}
									</a>
									<a 
										@click="onClicksection('detailreview',2)" 
										:class="{'active': sectionClick === -1 ? sectionCount === 3 : sectionClick === 2}"
									>
										{{t('10157')}}
									</a>
									<a 
										@click="onClicksection('detailaround',3)" 
										:class="{'active': sectionClick === -1 ? sectionCount === 4 : sectionClick === 3}"
									>
										{{t('10161')}}
									</a>
								</article>
							</div>
							<!-- 숙소 리스트 -->
							<article class="detail__list" v-if="hotel.length > 0" ref="section1" id="detailchoice" style="scroll-margin-top: 97px;">
								<h3 class="subtitle">{{t('10529')}}</h3>
								<ul class="list">
									<li v-for="room in rooms" :key="room.hotel_no + room.prod_no">
										<router-link :to="'/rooms/' + room.hotel_no + '/' + room.prod_no " class="list__link">
											<div class="list__wrap">
												<h3 class="list__title info-title" style="display:block;" v-if="urlhost === 'www.hellostay.co.kr'">
															{{room.prod_nm}}
													</h3>
												<div class="list__inner">
													<div class="list__image">
														<div class="list__imagebox noneimage">
															<div class="imagebg" :style="{'background-image': 'url(&quot;' + room.IMG_URL + '&quot;)'}"></div>
														</div>
													</div>
													<div class="list__data">
														<h3 class="list__title info-title" v-if="urlhost !== 'www.hellostay.co.kr'">
															{{room.prod_nm}}
														</h3>
														<div class="list_facility info-facility">
															{{room.PROD_DESC2}}
														</div>
														<div class="list_people info-people">
															{{t('10292')}} {{room.base_cnt}} / {{t('10058')}} {{room.max_cnt}} <!-- 재번역 체크 -->
														</div>
														<div class="list__box">
															<ul class="list__info">
																<li v-if="room.std_dayuse !== null" v-show="room.D_SALES_YN === 'Y'">
																	<div class="list__info-type">
																		<span class="list__badge">{{t('10558')}}</span> {{room.dayuse_hours}}{{t('10059')}} 
																	</div><!-- 재번역 체크 -->
																	<div class="list__info-price info-price" v-if="room.avail_yn_du === 'N'">
																		<div class="info-price__current">
																			<span class="grey1">{{t('10559')}}</span>
																		</div>
																	</div>
																	<div class="list__info-price info-price" v-else-if="!isWeekend">
																		<div class="info-price__sale" v-if="(100 - (room.dwday_amt / room.std_dayuse * 100)).toFixed(0) > 0">
																			<strong>{{(100 - (room.dwday_amt / room.std_dayuse * 100)).toFixed(0)}}%</strong>
																			<span>{{room.std_dayuse.toLocaleString('es-US')}}</span>
																		</div>
																		<div class="info-price__current">
																			<strong>{{room.dwday_amt.toLocaleString('es-US')}}</strong>{{t('10088')}}
																		</div>
																	</div>
																	<div class="list__info-price info-price" v-else>
																		<div class="info-price__sale" v-if="(100 - (room.dwday_amt / room.std_dayuse * 100)).toFixed(0) > 0">
																			<strong>{{(100 - (room.dwday_amt / room.std_dayuse * 100)).toFixed(0)}}%</strong>
																			<span>{{room.std_dayuse.toLocaleString('es-US')}}</span>
																		</div>
																		<div class="info-price__current">
																			<strong>{{room.dwend_amt.toLocaleString('es-US')}}</strong>{{t('10088')}}
																		</div>
																	</div>
																</li>
																<li v-show="room.S_SALES_YN === 'Y'">
																	<div class="list__info-type">
																		<span class="list__badge list__badge--strong">{{t('10555')}}</span> {{room.stay_stime}} ~ 
																	</div>
																	<div class="list__info-price info-price" v-if="room.avail_yn === 'N'">
																		<div class="info-price__current">
																			<span class="grey1">{{t('10559')}}</span>
																		</div>
																	</div>
																	<div class="list__info-price info-price" v-else-if="!isWeekend">
																		<div class="info-price__sale"  v-if="(100 - (room.wday_amt / room.std_amt * 100)).toFixed(0) > 0">
																			<strong>{{(100 - (room.wday_amt / room.std_amt * 100)).toFixed(0)}}%</strong>
																			<span>{{room.std_amt.toLocaleString('es-US')}}</span>
																		</div>
																		<div class="info-price__current">
																			<strong>{{room.wday_amt.toLocaleString('es-US')}}</strong>{{t('10088')}}
																		</div>
																	</div>
																	<div class="list__info-price info-price" v-else>
																		<div class="info-price__sale"  v-if="(100 - (room.wday_amt / room.std_amt * 100)).toFixed(0) > 0" >
																			<strong>{{(100 - (room.wday_amt / room.std_amt * 100)).toFixed(0)}}%</strong>
																			<span>{{room.std_amt.toLocaleString('es-US')}}</span>
																		</div>
																		<div class="info-price__current">
																			<strong>{{room.wend_amt.toLocaleString('es-US')}}</strong>{{t('10088')}}
																		</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</router-link>
									</li>
								</ul>
							</article>
							<!-- 숙소 상세정보 -->
							<div v-if="hotelInfo.length > 0" class="detail__info" id="detailinfo" ref="section2" style="scroll-margin-top: 97px;">
								
								<!-- 숙소소개 -->
								<div class="detail__info-item">
									<h3 class="detail__subtitle">
										{{t('10531')}}
									</h3>
									<p class="detail__description description" :class="[{overflow__text: hide}, 'text']" ref="text">
										{{hotelInfo[0].hotel_info}}
									</p>
									<a class="btn-detailmore" @click="setMore" v-if="hide">
										<span>{{t('10532')}}</span>
									</a>
								</div>
								<!-- 편의시설 및 서비스 -->
								<div class="detail__info-item">
									<h4 class="detail__subtitle">
										{{t('10533')}}
									</h4>
									<ul class="facilities">
										<li v-for="service in services.filter(service => service.FACI_FLAG === '02')" :key="service.FACI_NO">
											<div class="facility__item">
												<i class="facility__icon" v-if="service.IMG_URL !== null && service.IMG_URL.length > 0">
													<img :alt="service.FACI_NM" :src="service.IMG_URL">
												</i>
												<span class="facility__text">
													{{service.FACI_NM}}
												</span>
											</div>
										</li>
									</ul>
									<a class="btn-detailmore" @click="handleOpenFacility">
										<span>{{t('10532')}}</span>
									</a>
								</div>
								<!-- 이용안내 -->
								<div class="detail__info-item">
									<h4 class="detail__subtitle">
										{{t('10534')}}
									</h4>
									<p class="detail__description description" 
										v-html="(hotelInfo[0].rsv_app_info?.split('\n').join('<br />') ?? '')">
									</p>
								</div>
							</div>							
							<!-- 숙소 맵 -->
							<article id="detail-map" class="detail__map" @click="openhandlekakaomap">
								<!-- [D] 샘플 지도 영역 -->
							</article>
							<!-- 숙소후기 -->
							<article class="detail__review" id="detailreview" v-if="hotel.length > 0" ref="section3" style="scroll-margin-top: 97px;">
								<h3 class="title subtitle">
									{{t('10157')}}
									<small v-if="hotel.length > 0">({{hotel[0].REVIEWS.toLocaleString("en-US")}})</small>
									<router-link :to="'/rooms/reviews/' + hotel[0].HOTEL_NO" class="btn-more">
										MORE
									</router-link>
								</h3>
								<p class="noreview" v-if="hotel[0].REVIEWS === 0">
									{{t('10535')}}
								</p>
								<div class="reviews" v-else>
									<!-- 리뷰 1 -->
									<div class="review__item" v-for="review in reviews.slice(0,5)" :key="review.BOOKING_NO">
										<div class="review__body">
											<div class="flex">
												<div class="review__type">
													{{review.PROD_NM}} - {{review.PROD_TYPE}}
												</div>
												<div class="review__name">
													{{review.REVIEW_NICKNM}}
												</div>
											</div>
											<div class="flex">
												<div class="review__grad">
													<i v-for="i in 5" :key="i" class="icon" :class="i <= review.SCORE ? 'icon-star' : 'icon-star--light'"></i>
												</div>
												<div class="review__date">
													{{review.YMD}}
												</div>
											</div>
											<div class="review__image noneimage" v-if="review.images.length > 0">
												<div class="imagebg" :style="{'background-image': 'url(\'' + review.images[0].IMG_URL + '\')'}" />
											</div>
											<div class="review__desc">
												{{review.REVIEW}}
												<button type="button" class="btn-police" @click="handleOpenPolice(review.BOOKING_NO)">
													{{t('10536')}}
												</button>
											</div>
										</div>
									</div>
								</div>
							</article>
							<!-- 가까운 숙소 추천 -->
							<article class="detail__related" id="detailaround" ref="section4" style="scroll-margin-top: 97px;">
								<h3 class="subtitle">{{t('10161')}}</h3>
								<h3 class="title detail__subtitle">
									{{t('10165')}}
								</h3>
								<div class="carousel">
									<!-- [REST] 같은 동 상품 노출 -->
									<div class="carousel__slider">
										<swiper :slides-per-view="1.4" :space-between="16">
											<swiper-slide v-for="aroundHotel in around" :key="aroundHotel.HOTEL_NO">
												<router-link :to="'/rooms/' + aroundHotel.HOTEL_NO" class="carousel__link">
													<div class="carousel__image noneimage">
														<div class="imagebg" :style="{'background-image': 'url(\'' + aroundHotel.IMG_URL + '\')'}">
														</div>
													</div>
													<div class="carousel__info">
														<h3 class="carousel__title info-title" v-if="lang === 'ko'">
															{{aroundHotel.HOTEL_NM}}
														</h3>
														<h3 class="carousel__title info-title" v-else>
															{{aroundHotel.HOTEL_ENM}}
														</h3>
														<div class="carousel__grad info-grad"  v-if="aroundHotel.REVIEWS > 0">
															<span class="carousel__icon info-icon" >
																<i class="icon icon-star"></i>
															</span>
															<span class="carousel__gradnum info-gradnum" >
																{{aroundHotel.SCORE.toFixed(1)}}
															</span>
															<span class="carousel__gradcounter info-gradcounter">
																({{aroundHotel.REVIEWS.toLocaleString("en-US")}})
															</span>
														</div>
														<div class="carousel__price">
															<span class="carousel__saleprice" v-if="(100 - (aroundHotel.SALES_AMT / aroundHotel.STD_AMT * 100)).toFixed(0) > 0">
																{{(100 - (aroundHotel.SALES_AMT / aroundHotel.STD_AMT * 100)).toFixed(0)}}%
															</span>
															<span class="carousel__currentprice">
																<strong>{{aroundHotel.SALES_AMT?.toLocaleString("en-US")}}</strong>{{t('10088')}}
															</span>
														</div>
													</div>
												</router-link>
											</swiper-slide>
										</swiper>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<!-- navigation -->
	<div id="navigation" class="navigation">
		<Toast v-if="isShowPickToast" :type="'pick'" :handleClose="()=>{isShowPickToast = false}" :closeSecond="3"/>
		<div class="navigation-button">
			<a class="btn btn-confirm" href="#detailchoice">
				객실 선택하기
			</a>
			<ul class="item__menu">
				<li>
					<a>
                        <i class="icon icon-pick--point" v-if="picked" @click="unpick"></i> 
                        <i class="icon icon-pick--light" v-else @click="pick"></i>
					</a>
				</li>
			</ul>
		</div>
	</div>
	<Coupon v-if="openCoupon" :handleClose="handleCloseCoupon" :hotelNo="hotelNo" />
	<Facility v-if="openFacility" :handleClose="handleCloseFacility" :hotelNo="hotelNo" />
	<MessagePickunset v-if="openMessagePickunset" :handleClose="()=>openMessagePickunset = false" :handleSubmit="unPickAction"/>
	<MessageLogin v-if="!isLogin && openLogin" :handleClose="()=> openLogin=false" :handleSubmit="handleLoginSubmit"/>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<MessagePolice v-if="openPolice" :handleClose="()=> openPolice=false" :hotelNo="hotelNo" :reviewSeq="reviewSeq" />
	<kakaomap v-if="openkakaomap" :handleClose="()=> openkakaomap=false" />
	<!-- //navigation -->
	<TheNavigation></TheNavigation>
</template>

<style scoped>
	.overflow__text {
		text-overflow: ellipsis;
		overflow-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.text {
		overflow: hidden;
		white-space: pre-line;
	}
</style>

<script>
import { computed, onMounted, onUpdated, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import numberWithCommas from '@/utils/numberWithCommas';
import Coupon3 from "@/components/layers/Coupon3";
import Facility from "@/components/layers/Facility";
import MessagePickunset from '@/components/layers/Message_pickunset';
import MessageLogin from '@/components/layers/Message_login';
import MessagePolice from "@/components/layers/Message_police"
import kakaomap from "@/components/layers/kakaomap"
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Toast from "@/components/Toast";
import commonApi from "@/urls/common";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 


dayjs.locale("ko");
export default {
	components: {
		Coupon: Coupon3,
		Facility,
		Toast,
		MessagePickunset,
		MessageLogin,		
		ErrorAlert,
		kakaomap,
		MessagePolice
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const hotelNo = route.params.id;
		const openErrorAlert = ref(false)

		const inSelectedDateRange = ref(null);
		const nowSlideIndex = ref(0); 
		const hide = ref(true);
		const text = ref(null);
		const hotel = computed(() => (store.state.mrm02.hotel[hotelNo] || []));
		const hotelInfo = computed(() => (store.state.mrm02.mrm0201[hotelNo] || []));
		const services = computed(() => store.state.mrm02.mrm0204[hotelNo] || []);
		const around = computed(() => store.state.mrm02.around.filter(item => item.HOTEL_NO !== hotelNo).slice(0,20));
		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);

		const isShowPickToast = ref(false);
		const picked = ref(false);
		const hasChild = ref(false);

		let map = null;
		const navRectWidth = computed(() => navTmp.value?.getBoundingClientRect().width);
		const navTmp = computed(()=>navRef.value.cloneNode(true));
		const navRef = ref(null);
		const navRefTop = ref(Number.MIN_SAFE_INTEGER);
		const topNavShow = ref(false);

		const section1 = ref(null);
		const section2 = ref(null);
		const section3 = ref(null);
		const section4 = ref(null);
		const sectionList = computed(()=>[section1.value, section2.value, section3.value, section4.value]);
		const sectionPos = ref([0,0,0,0]);
		const sectionPosOffset = window.innerHeight -96 - 16;
		const sectionClickOffset = -96 - 15;
		const sectionCount = ref(0);
		const sectionClick = ref(-1);
		const sectionOffset = ref(-1);
		const lang = ref();

		const openCoupon = ref(false);
		const openFacility = ref(false);
		const openLogin = ref(false);
		const openMessagePickunset = ref(false);
		const openkakaomap = ref(false);
    	const unPickAction = ref(()=>{});
		const urlhost = ref();
		const setSlideCurrentIndex = (swiper) => {
			const index = swiper.realIndex;
			nowSlideIndex.value = index;
		}

		watch(() => hotel.value, (newVal) => {
			if (newVal.length > 0) {
				picked.value = newVal[0].MYPICK_YN === 'Y';
			}
			store.dispatch("mrm02/fetchAround", {
				sdate: dayjs(inSelectedDateRange.value.start).format("YYYYMMDD"),
				edate: dayjs(inSelectedDateRange.value.end).format("YYYYMMDD"),
				area_cd: newVal[0].AREA_CD,
				adult_cnt: store.state.mhm01.selectedMember ? store.state.mhm01.selectedMember.adultCount : undefined,
				child_cnt: store.state.mhm01.selectedMember ? store.state.mhm01.selectedMember.childCount : undefined,
				room_cnt: 1,
				cp_yn: "N",
				mem_id: userData.value.mem_token,
			})
		});

		const pick = () => {
			if(!isLogin.value) {
				openLogin.value = true;
				return;
			}
			picked.value = true;
			store.dispatch("mmy01/postMmy0120", { proc_cd: '10', prod_flag: '01', mem_id:  userData.value.mem_token, hotel_no: hotelNo });
			isShowPickToast.value = true;
		}
            
		const handleLoginSubmit = () => {
			router.push("/layers/login");
		}

		const openhandlekakaomap = () =>{
			openkakaomap.value = true;
		}

		const unpick = () => {
			openMessagePickunset.value = true;
			unPickAction.value = () => {
				if(hotel.value[0].MYPICK_SEQ === 0) {
					store.dispatch("mrm02/fetchRoomDetail", { hotelNo, memId: userData.value.mem_token }).then(() => {
						store.dispatch("mmy01/fetchMmy01201", { proc_cd: '01', mem_id: userData.value.mem_token, seq: hotel.value[0].MYPICK_SEQ })
						.then(() => {
							picked.value = false;
						});
					});
				} else {
					store.dispatch("mmy01/fetchMmy01201", { proc_cd: '01', mem_id: userData.value.mem_token, seq: hotel.value[0].MYPICK_SEQ })
					.then(() => {
						picked.value = false;
					});
				}
				openMessagePickunset.value = false;
			};
		};
		
		const handleScroll = () => {
			if(sectionOffset.value === -1) {
				sectionClick.value = -1;
			}
			if(window.pageYOffset !== 0){
				topNavShow.value = true;
			}
			else {
				topNavShow.value = false;
			}
			if (window.pageYOffset > navRefTop.value && 0 < window.pageYOffset && 0 < navRefTop.value) {
				/* tmp 추가 */
				hasChild.value = true;
				navRef.value.parentNode.appendChild(navTmp.value);
				navRef.value.style.position = 'fixed';
				navRef.value.style.top = "48px";
				navRef.value.style.width = navRectWidth.value + "px";
				navRef.value.style['z-index'] = "5";
			} else {
				/* tmp 제거 */
				if(hasChild.value){
					navRef.value.parentNode.removeChild(navTmp.value);
					hasChild.value = false;
				}
				navRef.value.style.position = 'static';
				navRef.value.style.top = '';
			}

			sectionCount.value = sectionPos.value.filter(x => x - window.pageYOffset + sectionPosOffset < 0).length;

			const scrollHeight =  document.body.offsetHeight - window.innerHeight;
			const reached = Math.min(sectionOffset.value, scrollHeight) - window.pageYOffset < 1;
			if (reached){
				sectionOffset.value = -1;
			}
		};

		const onClicksection = (id, index) => {
			let scrollDiv = window.pageYOffset + document.getElementById(id).getBoundingClientRect().top + sectionClickOffset;
			sectionOffset.value = scrollDiv;
			sectionClick.value = index;
			window.scrollTo({ top: scrollDiv, behavior: "smooth" });
		}

		store.watch(() => [
			store.state.mhm01.selectedDateRange,
			store.state.mhm01.selectedMember,
		], ([selectedDateRange, selectedMember]) => {

			if (selectedDateRange === null) {
				inSelectedDateRange.value = {
					start: dayjs().toISOString(),
					end: dayjs().add(1, "day").toISOString(),
				}
			}else {
				inSelectedDateRange.value = selectedDateRange;
			}

			store.dispatch("mrm02/fetchRoomDetail", { 
				hotelNo, 
				arrDate: dayjs(inSelectedDateRange.value.start).format("YYYYMMDD"),
				depDate: dayjs(inSelectedDateRange.value.end).format("YYYYMMDD"),
				adultCnt: selectedMember ? selectedMember.adultCount : undefined,
				roomCnt: 1,
				childCnt: selectedMember ? selectedMember.childCount : undefined,
				memId: userData.value.mem_token
			});
		}, {immediate: true})

		const goAroundList = async () => {
			const data = await commonApi.getCd086({});
			const area = data.data.Res_data.find(x => x.area_cd2 === hotel.value[0].AREA_CD)
			if (area) {
				router.push("/rooms/list?location=" + JSON.stringify({"area_cd1":area.area_cd1,"area_cd2":area.area_cd2,"area_nm1":area.area_nm1,"area_nm2":area.area_nm2}));
			}else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "없는 지역입니다. 관리자에 문의 부탁드리겠습니다."});
				openErrorAlert.value = true;
				//alert("없는 지역입니다. 관리자에 문의 부탁드리겠습니다.")
			}
		}

		onMounted(() => {
			urlhost.value = store.state.app.urlhost;
			lang.value = store.state.app.lang;
			i18n.global.locale.value = store.state.app.lang;
			store.dispatch("mrm02/fetchMrm0201", { hotelNo });
			store.dispatch("mrm02/fetchMrm0203", { hotelNo });
			store.dispatch("mrm02/fetchMrm0204", { hotelNo });
			store.dispatch("mrm02/fetchMrm0205", { hotelNo });
			store.watch(() => store.state.mrm02.hotel[hotelNo], (hotel) => {
				if (hotel && hotel.length > 0){
					const container = document.getElementById('detail-map'); //지도를 담을 영역의 DOM 레퍼런스
					const hotelLocation = new window.kakao.maps.LatLng(hotel[0].LOC_LATITUDE, hotel[0].LOC_LONGITUDE);
					const options = { //지도를 생성할 때 필요한 기본 옵션
						center: hotelLocation, //지도의 중심좌표.
						level: 3 //지도의 레벨(확대, 축소 정도)
					};
					map = new window.kakao.maps.Map(container, options);

					// 마커를 생성합니다
					new window.kakao.maps.Marker({
						map: map,
						position: hotelLocation
					});
				}
			}, {immediate: true});
			window.addEventListener("scroll", handleScroll);
		});

		onUnmounted(()=>{
			window.removeEventListener("scroll", handleScroll);
			
		});

		onUpdated(() => {
			sectionPos.value = sectionList.value.map(x => 
				window.pageYOffset + x?.getBoundingClientRect().top - window.innerHeight);
			navRefTop.value = window.pageYOffset + (hasChild.value ? navTmp.value?.getBoundingClientRect().top : navRef.value?.getBoundingClientRect().top) - 48;
			const node = text.value;
			if(node !== null && hotelInfo.value.length > 0 && hotel.value.length > 0) {
				const hasOverflowingChildren =
					node.offsetHeight + 5 < node.scrollHeight ||
					node.offsetWidth < node.scrollWidth;
				if (!hasOverflowingChildren) {
					hide.value = false;
				}
			}
			
			
		})

		const openPolice = ref(false);
		const reviewSeq = ref(0);

		const handleOpenPolice = (seq) => {
			reviewSeq.value = seq;
			openPolice.value = true;
		}
		const { t }= useI18n() //번역필수 모듈
		
		return {
			handleLoginSubmit,
			isLogin,
			openLogin,
			navRef,
			setSlideCurrentIndex,
			hotelNo,
			hotel,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			picked,
			isShowPickToast,
			pick,
			unpick,
			rooms: computed(() => (store.state.mrm02.rooms[hotelNo] || [])),
			hotelInfo,
			hotelImages: computed(() => (store.state.mrm02.mrm0203[hotelNo] || [])),
			dateRangeText: computed(() => (
				dayjs(inSelectedDateRange.value.start).format("MM/DD")
				+ " - " +
				dayjs(inSelectedDateRange.value.end).format("MM/DD")
				+ ", " + dayjs(inSelectedDateRange.value.end).diff(dayjs(inSelectedDateRange.value.start), "day") +" "+t('10855'))),
			numberWithCommas,
			openShare: () => {
				store.dispatch("app/requestShare");
			},
			goBack: () => {
				router.back();
			},
			nowSlideIndex,
			isWeekend: dayjs().day() === 0 || dayjs().day() === 6,
			hide,
			text,
			setMore: () => hide.value = false,
			reviews: computed(() => ((store.state.mrm02.mrm0205[hotelNo] || []).map(review => ({
				...review,
				YMD: dayjs(review.YMD).format("YYYY.MM.DD"),
			})) )),
			services,
			openCoupon,
			handleCloseCoupon: () => {
				openCoupon.value = false;
			},
			handleOpenCoupon: () => {
				openCoupon.value = true;
				if(openCoupon.value === true){
					document.querySelector('body').css({'height':'100%','overflow':'hidden'})
				}else{
					document.querySelector('body').css({'height':'auto%','overflow':'none'})
				}
			
			},
			openFacility,
			handleCloseFacility: () => {
				openFacility.value = false;
			},
			handleOpenFacility: () => {
				openFacility.value = true;
			},
			topNavShow,
			section1,
			section2,
			section3,
			section4,
			sectionPos,
			sectionCount,
			sectionClick,
			onClicksection,
			openMessagePickunset,
			unPickAction,
			around,
			goAroundList,
			openPolice,
			openkakaomap,
			openhandlekakaomap,
			lang,
			handleOpenPolice,
			reviewSeq,
			t,
			urlhost,
			i18n
		}
	},
}
</script>