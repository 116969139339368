<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap layer__wrap--centered">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10651')}}
						<small>{{t('10652')}}</small>
					</h3>
					<button class="btn-close" @click="handleClose">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<div class="layer__box">
						<section class="layer-coupon">
							<ul class="coupon-list" style="max-height:inherit;border-top:none;border-bottom:1px solid var(--grey3)">
								<li v-for="(coupon, index) in coupons" :key="index">
									<div class="coupon-list__wrap" :class="{disabled: coupon.USE_YN !== 'Y'}">
										<div class="radio">
											<div class="coupon-list__name ellipsis">
												{{coupon.CP_NM}}
											</div>
											<div class="coupon-list__title">
												{{coupon.CP_AMT_NM}}
											</div>
											<div class="coupon-list__desc">
												{{coupon.CP_DESC}}
											</div>
										</div>
									</div>
								</li>
							</ul>
							<div class="layer-coupon__notice">
								<h3 class="layer-coupon__title">
									{{t('10653')}}
								</h3>
								<ul class="notice-list notice-list--blit">
									<li>
										{{t('10654')}}
									</li>
									<li>
										{{t('10655')}}
									</li>
									<li>
										{{t('10656')}}
									</li>
									<li>
										{{t('10657')}}
									</li>
								</ul>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ["handleClose", "hotelNo"],
	setup(props) {
		const store = useStore();
		const userData = computed(() => store.state.userData);
		const { t }= useI18n() 

		onMounted(() => {
			store.dispatch("mrm02/fetchMrm0206", {hotelNo: props.hotelNo, mem_id: userData.value.mem_token});
		});
		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

		return {
			coupons: computed(() => store.state.mrm02.mrm0206[props.hotelNo]),
			 t,  //번역필수 모듈
		  i18n, 
		}
	},
}
</script>