<template>
	<section class="layer" style="display:block">
		<div class="layer__dim"></div>
        <!-- [D] 가로/세로 가운데 정렬 레이어 일 경우 layer__wrap--message 클래스 추가 -->
		<div class="layer__wrap layer__wrap--centered" v-if="isSended">
			<div class="layer-message">
				<div class="layer-message__top">
					<h3 class="layer-message__title">
						{{t('10700')}}
					</h3>
				</div>
				<div class="layer-message__btns">
					<button type="button" class="btn skyblue" @click="handleClose">
						{{t('10143')}}
					</button>
				</div>
			</div>
		</div>
		<div class="layer__wrap layer__wrap--centered" v-else>
			<div class="layer-message">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10701')}}
					</h3>
				</header>
				<div class="layer__body">
					<div class="police">
						<ul class="police__list">
							<li v-for="item in mmy01122" :key="item.BADREVIEW_CD">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant" :value="item.BADREVIEW_CD" v-model="select">
										<span class="radio__bg"></span>
										{{item.BADREVIEW_NM}}
									</div>
								</label>
							</li>
						</ul>
						<div class="police__textarea" v-if="select === '99'">
							<div class="frms__item">
								<div class="review__text" style="margin-right:0">
									<textarea class="ta ta--transparent" :placeholder="t('10826')" style="height:64px;" v-model="otherText" maxlength="200"></textarea>
									<!-- 재번역체크 -->
									<div class="review__number">
										<span>{{otherText.length}}</span> / 200
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
                <div class="layer-message__btns">
                    <button type="button" class="btn" @click="handleClose">
                        {{t('10250')}}
                    </button>
                    <button type="button" class="btn" :class="{purple: complete}" :disabled="!complete" @click="submit">
                        {{t('10702')}}
                    </button>
                </div>
			</div>
		</div>
	</section>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import api from "@/urls/mmy01";
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 
export default {
	props: ["handleClose", "hotelNo", "reviewSeq"],
	setup(props) {
		const select = ref();
		const otherText = ref("");
		const store = useStore();
		const userData = computed(() => store.state.userData)
		const isSended = ref(false);
		const { t }= useI18n() 

		const submit = async () => {
			await api.getMmy01122({proc_cd: "11", mem_id: userData.value.mem_id, badreview_cd: select.value, badreview_desc: otherText.value, hotel_no: props.hotelNo, seq: props.reviewSeq});
			isSended.value = true;
		}

		onMounted(() => {
			store.dispatch("mmy01/getMmy01122");
		})

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
		return {
			select,
			otherText,
			t,  //번역필수 모듈
		  i18n, 
			complete: computed(() => (select.value !== undefined && select.value !== "99") || (select.value === "99" && otherText.value.length >= 10)),
			mmy01122: computed(() => store.state.mmy01.mmy01122),
			submit,
			isSended
			
		}
	},
}
</script>