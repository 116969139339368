<template>
    <a class="toast" v-if="type === 'cart'" style="z-index: 9999">
        <span>
            <i class="icon icon-cart--white"></i>
            <span>장바구니에 상품이 담겼습니다.</span>
        </span>
        <router-link to="/mypage/cart" custom v-slot="{ navigate }">
            <span style="color:#52A7FF" @click="navigate">
                바로가기
                <i class="icon icon-right--point"></i>
            </span>
        </router-link>
    </a>
    <a class="toast" v-if="type === 'check'" style="z-index: 9999;position:fixed;bottom:0;left:0;width:100%;box-sizing: border-box;">
        <span>
            <i class="icon icon-check--white"></i>
            <span>조건을 선택해 주세요</span>
        </span>
    </a>
    <a class="toast" v-if="type === 'pick'" style="z-index: 9999;position:fixed;bottom:80px;left:0;width:100%;margin-right:0px;">
        <span>
            <i class="icon icon-pick--white"></i>
            <span>픽 목록에 추가되었습니다</span>
        </span>
        <router-link to="/pick/rooms" custom v-slot="{ navigate }">
            <span style="color:#52A7FF" @click="navigate">
                바로가기
                <i class="icon icon-right--point"></i>
            </span>
        </router-link>
    </a>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
export default {
    props: ["closeSecond", "type", "handleClose",],
    setup(props) {
        onMounted(() => {
            if(props.closeSecond !== 0 && props.closeSecond !== undefined) {
                setTimeout(() => {
                    props.handleClose();
                }, props.closeSecond * 1000);
            }
        });
    },
}
</script>
