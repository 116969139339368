<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="handleClose" class="nav__btn nav__btn--prev">
								<i class="icon icon-left">{{t('10030')}}</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10533')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
				<div class="layer__fullbody">
					<div class="box">
						<!-- 인기시설 -->
						<div class="facility__box" v-for="service in Object.keys(services)" :key="service">
							<h4 class="detail__subtitle">
								<i class="i icon-common" v-if="service === '공용시설'"></i>
								<i class="i icon-security" v-if="service === '안전보안'"></i>
								<i class="i icon-roomservice" v-if="service === '식음료'"></i>
								<i class="i icon-location2" v-if="service === '위치'"></i>
								<i class="i icon-car" v-if="service === '차량'"></i>
								<i class="i icon-rental" v-if="service === '렌탈'"></i>
								<i class="i icon-media" v-if="service === '미디어'"></i>
								<i class="i icon-cleanup" v-if="service === '위생'"></i>
								<i class="i icon-activity" v-if="service === '액티비티'"></i>
								<i class="i icon-etc" v-if="service === '기타'"></i>
								{{service}}
							</h4>
							<ul class="facilities">
								<li v-for="a in services[service]" :key="a.FACI_NO">
									<div class="facility__item">
										<i class="facility__icon" v-if="a.IMG_URL !== null && a.IMG_URL.length > 0">
											<img :alt="a.FACI_NM" :src="a.IMG_URL">
										</i>
										<span class="facility__text">
											{{a.FACI_NM}}
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { onBeforeRouteLeave } from 'vue-router'
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

export default {
	props: ["handleClose", "hotelNo"],
	setup(props) {
		const store = useStore();
		const { t }= useI18n() 
		const services = computed(() => {
			const servicess = store.state.mrm02.mrm0204[props.hotelNo];
			if(servicess === undefined) {
				return {};
			}else {
				return servicess.reduce(function (r, a) {
					r[a.FACI_FLAG_NM] = r[a.FACI_FLAG_NM] || [];
					r[a.FACI_FLAG_NM].push(a);
					return r;
				}, Object.create(null));
			}
		});

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})
		
		return {
			services,
			 t,  //번역필수 모듈
		  i18n, 
		}
	},
}
</script>